import { Box, Container, Grid, Typography, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import blogPostI_3_mage_3 from "../assets/blog-post-3.webp";
import React from "react";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 300,
  textAlign: "start",
  color: "#FCFCFC",
  lineHeight: "2",
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.5rem",
  },
}));

const BlogPostContent3 = () => {
  const [copySuccess, setCopySuccess] = React.useState("");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100%",
        pt: "5rem",
        background: "#1C1D20",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "70vw" }}
      >
        <Box
          sx={{
            my: "1rem",
            textAlign: "center",
          }}
        >
          <GradientText>
            Understanding Data Analytics: A Deeper Dive into the Process
          </GradientText>
          <TitleText>Data Analysis Steps: From Collection to Action</TitleText>
          <BodyText>5 min read - mar 21, 2024</BodyText>
        </Box>
        <Box sx={{ my: "1rem" }}>
          <img
            src={blogPostI_3_mage_3}
            alt="AI and Predictive Analytics"
            style={{ width: "100%", height: "60%", borderRadius: "20px" }}
          />
        </Box>
        <Box sx={{ my: "2rem" }}>
          <BodyText>
            The journey of data analytics is a meticulous process that
            transforms raw data into actionable insights. This journey can be
            broken down into several key steps:
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            1. Determining Data Requirements
          </SubTitleText>
          <BodyText>
            The very first step is to understand what we're looking for in the
            data. This involves deciding how to group the data—maybe by age,
            income, demographic, or gender—and understanding whether we're
            dealing with numerical values or categories. It's like setting the
            destination before starting a journey.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            2. Collecting the Data
          </SubTitleText>
          <BodyText>
            With a clear goal in mind, the next step is gathering the data. This
            could involve various sources, such as online platforms, sensors,
            surveys, or even direct observations. Imagine casting a wide net to
            capture as much relevant information as possible.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            3. Organizing the Data
          </SubTitleText>
          <BodyText>
            Once we have our data, we need to put it in order. This often
            involves using spreadsheets or specialized software that can handle
            statistical data. Think of it as setting up your tools before
            starting a complex project.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>4. Cleaning the Data</SubTitleText>
          <BodyText>
            Data rarely comes neat and tidy. This step involves scrubbing the
            data clean of duplicates, errors, or incomplete information,
            ensuring that it's primed for analysis. It's akin to prepping
            ingredients before cooking a gourmet meal.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            Types of Data Analytics:
          </SubTitleText>
          <BodyText>
            With a clearer understanding of the data analysis process, we can
            revisit the types of data analytics with fresh eyes:
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            Descriptive Analytics:
          </SubTitleText>
          <BodyText>
            The first layer, descriptive analytics, looks back over time to tell
            us what has happened. This is our narrative, painting a picture of
            past performances, trends, and outcomes. For instance, a business
            might use descriptive analytics to understand changes in sales
            volume over the past quarter or to see how many views a website got
            during a particular campaign.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>Diagnostic Analytics:</SubTitleText>
          <BodyText>
            Taking a step deeper, diagnostic analytics helps us understand why
            those things happened. This stage involves more complex data inputs
            and sometimes a bit of educated guessing. It's detective work:
            analyzing whether a drop in sales was due to an external factor like
            a holiday or an internal one like a change in marketing strategy.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>Predictive Analytics:</SubTitleText>
          <BodyText>
            Now we're looking ahead with predictive analytics, which uses
            historical data to make informed guesses about future events. This
            is where data analytics starts to feel a bit like fortune-telling,
            but it's grounded in solid data. Businesses might use predictive
            analytics to forecast sales during an upcoming season or to predict
            customer churn rates based on current trends.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            Prescriptive Analytics:
          </SubTitleText>
          <BodyText>
            Finally, prescriptive analytics offers recommendations on what
            actions to take to achieve goals or solve problems. It combines
            insights from all previous analytics types to not just predict what
            could happen, but also to suggest a course of action. For instance,
            if predictive analytics shows a high likelihood of a hot summer,
            prescriptive analytics might suggest strategies to increase the
            production of summer products.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            Integrating the Analysis Process into Business Strategy
          </SubTitleText>
          <BodyText>
            Understanding these steps and types of analytics is crucial, but the
            real magic happens when businesses integrate this process into their
            everyday decision-making. It's not just about having the data or
            even the insights—it's about using those insights to make informed,
            strategic decisions that drive growth and efficiency.
            <li>
              <strong>Start with Clear Objectives:</strong> Knowing what you
              want to achieve with your data analysis is crucial. Whether it's
              improving customer satisfaction, optimizing operations, or
              increasing sales, having clear goals will guide your data analysis
              process from the very beginning.
            </li>
            <li>
              <strong>Build the Right Team: </strong> Having a team that
              understands not only how to perform data analysis but also how to
              interpret and apply the results is key. This might include data
              scientists, business analysts, and decision-makers who can act on
              the insights provided.
            </li>
            <li>
              <strong>Foster a Data-Driven Culture: </strong> Encourage everyone
              in the organization to think about how data can inform their
              decisions. This doesn't mean drowning in data but rather being
              thoughtful about what data is needed and how it can be used.
            </li>
            <li>
              <strong>Iterate and Learn: </strong> Data analysis is not a
              one-and-done process. It's about continuously learning from the
              data, adapting your strategies, and refining your approach. What
              worked yesterday might not work tomorrow, so staying agile and
              responsive to what the data tells you is crucial.
            </li>
            <br></br>
            By following these steps and understanding the different types of
            analytics, businesses can turn data into a strategic asset that
            informs decisions, drives innovation, and fosters growth.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>Takeaways</SubTitleText>
          <BodyText>
            <li>
              To harness the full potential of data analytics, businesses must
              start with a clear vision and a strategic approach. Identifying
              key areas where analytics can drive value, investing in the right
              tools and talent, and fostering a culture of data-driven
              decision-making are critical steps on this journey. As companies
              become more adept at using data analytics, they'll unlock new
              opportunities for growth, innovation, and competitive advantage.
            </li>
            <li>
              Embracing a data-driven culture requires more than just
              technology; it necessitates a shift in mindset across the
              organization. Encouraging curiosity, fostering a willingness to
              experiment, and learning from data insights are crucial steps
              toward embedding data into the DNA of a company's culture.
            </li>
            <li>
              The backbone of a data-driven culture is robust technology
              infrastructure. From data storage solutions to analytical tools,
              businesses need the right toolkit to gather, process, and analyze
              data effectively.
            </li>
            <li>
              As AI and machine learning become intertwined with data analytics,
              ethical considerations come to the forefront. Ensuring that
              algorithms are fair, transparent, and unbiased is essential in
              maintaining trust and integrity in data practices.
            </li>
          </BodyText>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          color: "white",
        }}
      >
        <Tooltip title={copySuccess || "Copy link"} placement="top" arrow>
          <IconButton
            onClick={copyToClipboard}
            sx={{ color: "white", transform: "scale(1.5)" }}
          >
            <IosShareRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #555",
          my: 2,
        }}
      />
    </Container>
  );
};

export default BlogPostContent3;
