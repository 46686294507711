import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NavigationIcon from "@mui/icons-material/Public";
import "./Header.css";

// Importa tu componente HamburgerMenu
import HamburgerMenu from "./HamburgerMenu"; // Ajusta la ruta según sea necesario

function Header() {
  return (
    <AppBar position="static" className="header appBarHeader">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <div className="title-container">
            <NavigationIcon />
            <span className="title-always">Barcelona</span>
            <span className="title-hover">Spain 🇪🇸</span>
          </div>
        </Typography>
        <HamburgerMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
