import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Box,
  IconButton,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Image1 from "../assets/frontpage-raiil-project.png";
import Image2 from "../assets/frontpage-pet-project.png";
import Image3 from "../assets/frontpage-gene-project.png";
import Image4 from "../assets/imagen4.webp";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #1C1D20 0%, #6A00DD 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "4rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  color: "#1C1D20",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.3rem",
  },
}));

type CarouselItem = {
  title: string;
  description: string;
  imageUrl: string;
  projectID: number;
};

const items: CarouselItem[] = [
  {
    title: "Raiil: Ordering app for restaurants",
    description:
      "Cloud-based management platform that simplifies and streamlines the order management process for restaurants. By providing an efficient, transparent system for handling orders, tracking, and payments, Raiil enhances the relationship between restaurants and suppliers, ensuring a more seamless supply chain operation.",
    imageUrl: Image1,
    projectID: 1,
  },
  {
    title: "Booking platform for veterinary services at home",
    description:
      "In today's world, every pet is unique and so should be their care. This project brings a web aplication to connect vet's owners with vet doctors in a seameless way, whe leverage the power of AI to create tailor-made treatments for our furry friends.",
    imageUrl: Image2,
    projectID: 2,
  },
  {
    title: "Gene fusion: Marketplace for outsourcing scientific services",
    description:
      "Biotech startups and SMEs struggle to easily find and compare dispersed biotechnological laboratory services online, compounded by the need for technical knowledge to make informed choices.",
    imageUrl: Image3,
    projectID: 3,
  },
  {
    title: "Mando - Machine learning model for competitive analysis",
    description:
      "Mando is an AI-driven machine learning tool that provides sentiment analysis for strategic competitive insight. It evaluates user emotions towards brands, guiding product development with data on competitors' market presence.",
    imageUrl: Image4,
    projectID: 4,
  },
];

const Carousel: React.FC = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const visibleSlides = isLargeScreen ? 3 : isMediumScreen ? 2 : 1;

  // Cálculo del índice para el loop de las tarjetas
  const [activeIndex, setActiveIndex] = React.useState(0);
  const lastIndex = items.length - visibleSlides;
  const handlePrev = () =>
    setActiveIndex((prev) => (prev > 0 ? prev - 1 : lastIndex));
  const handleNext = () =>
    setActiveIndex((prev) => (prev < lastIndex ? prev + 1 : 0));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#FCFCFC",
        minHeight: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} md={4} sx={{ p: "1rem" }}>
          <GradientText variant="h2" gutterBottom style={{ fontWeight: 900 }}>
            Recent projects
          </GradientText>
          <BodyText variant="h5" gutterBottom>
            Here you will find an overview from the most recent and relevant
            projects I've worked in. I hope you like it!
          </BodyText>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            position: "relative",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={handlePrev}
            sx={{
              position: "absolute",
              left: theme.spacing(-2),
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <Grid container spacing={2}>
            {items
              .slice(activeIndex, activeIndex + visibleSlides)
              .map((item, index) => (
                <Grid
                  item
                  xs={12 / visibleSlides}
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link
                    to={`/work/project-${item.projectID}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Card
                      raised
                      sx={{
                        maxWidth: isSmallScreen ? 250 : 400,
                        transition:
                          "transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: `0px 0px 10px 0px #9C87B5`,
                          cursor: "pointer",
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={item.imageUrl}
                        alt={item.title}
                        sx={{ height: 300, objectFit: "cover" }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
          </Grid>

          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: theme.spacing(-2),
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Carousel;
