import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Crear un tema con los colores deseados
const theme = createTheme({
  palette: {
    background: {
      default: "#242424", // Fondo blanco para la aplicación
    },
    primary: {
      main: "#242424", // Morado para elementos primarios (ajustar según el tono exacto de morado que desees)
    },
    secondary: {
      main: "#242424", // Morado para elementos secundarios (ajustar según el tono exacto de morado que desees)
    },
    text: {
      primary: "#242424", // Texto principal en negro
      secondary: "##FEFEFE", // Texto secundario en blanco (útil si tienes un fondo oscuro/morado en alguna parte)
    },
    // Añade otros colores según sea necesario
  },
  // Puedes añadir otras opciones de personalización como typography, breakpoints, etc.
});

// Crear la raíz del DOM y renderizar la aplicación envuelta en ThemeProvider
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
