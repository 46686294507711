import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "4rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  color: "#FCFCFC",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.3rem",
  },
}));

const variants = {
  open: { opacity: 1, height: "auto", marginTop: "1rem" },
  closed: { opacity: 0, height: 0, marginTop: "0rem" },
};

const Services: React.FC = () => {
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const toggleExpand = (id: number) => {
    setExpandedId(expandedId === id ? null : id); // Se expande o colapsa el elemento
  };

  const serviceItems = [
    {
      id: 1,
      title: "UX/UI design",
      description:
        "I offer comprehensive design services tailored to enhance the user experience and interface of digital products. My approach combines user-centered design principles with innovative aesthetics to create intuitive, accessible, and visually appealing interfaces.",
    },
    {
      id: 2,
      title: "Front-end development",
      description:
        " I specialize in creating interactive, responsive, and visually engaging websites and applications using JavaScript, CSS animations, and modern frameworks like React.js",
    },
    {
      id: 3,
      title: "Data Science",
      description:
        "I offer a comprehensive service designed to uncover insights, inform strategy, and optimize performance through data-driven decisions. ",
    },
    // ... otros servicios
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#1C1D20",
        minHeight: "90vh",
      }}
    >
      <Grid container sx={{ px: "2rem" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            px: "1rem",
          }}
        >
          <GradientText variant="h2" gutterBottom style={{ fontWeight: 900 }}>
            Services
          </GradientText>
          <BodyText variant="h5" gutterBottom>
            Creating AI-powered apps.
          </BodyText>
        </Grid>
        <Grid item xs={12} md={7}>
          {serviceItems.map((service) => (
            <React.Fragment key={service.id}>
              <Box
                sx={{
                  background:
                    "linear-gradient(to right, #6A00DD 0%, #1C1D20 100%)",
                  color: "#FCFCFC",
                  borderRadius: "1rem",
                  p: "1rem",
                  my: "0.5rem",
                  ":hover": {
                    cursor: "pointer",
                    background:
                      "linear-gradient(to right, #141516 0%, #1C1D20 100%)",
                    color: "#FCFCFC",
                  },
                }}
                onClick={() => toggleExpand(service.id)}
              >
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  {service.title}
                </Typography>
              </Box>
              <motion.div
                initial="closed"
                animate={expandedId === service.id ? "open" : "closed"}
                variants={variants}
                transition={{ duration: 0.5 }}
              >
                {expandedId === service.id && (
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "1rem",
                      p: "1rem",
                      my: "0.5rem",
                    }}
                  >
                    <Typography variant="body1" fontWeight="700">
                      {service.description}
                    </Typography>
                  </Box>
                )}
              </motion.div>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;
