import React, { useState } from "react";
import { Grid, Typography, Box, Container, styled } from "@mui/material";
import { Link } from "react-router-dom";

import Image1 from "../assets/imagen1-vet-doc.webp";
import Image2 from "../assets/imagen2-restaurant-owner.webp";
import Image3 from "../assets/imagen3-genes.webp";
import Image4 from "../assets/imagen4-social-media.webp";
import { motion } from "framer-motion";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

/*const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 300,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.8rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
  },
}));*/

const ValuesCards = styled(motion.div)({
  background: "linear-gradient(to top, #1C1D20 0%, #000 100%)",
  color: "white",
  borderRadius: "20px",
  width: "100%",
  height: "10rem",
  display: "flex",
  flexDirection: "column", // Stack the items vertically.
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#1C1D20",
    height: "100%",
    zIndex: 0,
    transition: "bottom 0.3s ease-in-out",
    color: "white",
  },
  "&:hover::before": {
    bottom: 0,
  },
  "& .fade": {
    // Create a class for elements that should fade in/out
    position: "absolute", // Position the typographies absolutely within the card
    width: "100%",
    textAlign: "center",
    transition: "opacity 0.3s ease-in-out", // Smooth transition for the opacity
    opacity: 0, // Start with the second typography hidden
    "&.show": {
      opacity: 1, // Default visible state for the first typography
    },
  },
  "&:hover .fade": {
    opacity: 0, // On hover, hide the first typography
  },
  "&:hover .fade.show-on-hover": {
    opacity: 1, // On hover, show the second typography
  },
});

const Work: React.FC = () => {
  const [isHovered, setIsHovered] = useState<number | null>(null);
  const TouchButton = styled(motion.div)({
    backgroundColor: "#6A00DD",
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative", // Importante para posicionar el pseudo-elemento
    overflow: "hidden", // Asegúrate de que el relleno no se salga del botón
    "&::before": {
      // Pseudo-elemento para el efecto de llenado
      content: '""',
      position: "absolute",
      bottom: "100%", // Empieza fuera de la vista, abajo del botón
      left: 0,
      right: 0,
      backgroundColor: "#000",
      height: "100%",
      zIndex: 0,
      transition: "bottom 0.3s ease-in-out", // Anima la propiedad bottom
    },
    "&:hover::before": {
      bottom: 0, // Al hacer hover, mueve el pseudo-elemento para "llenar" el botón
    },
  });
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100%",
        pt: "5rem",
        background: "#1C1D20",
      }}
    >
      <Box
        sx={{
          my: "1rem",
          textAlign: "center",
        }}
      >
        <GradientText>My work</GradientText>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {[Image1, Image2].map((image, index) => {
          const imageFirst = index % 2 === 0;

          return (
            <React.Fragment key={index}>
              {imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      borderRadius: "20px",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      position: "relative",
                    }}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    <Link
                      to={`/work/project-${index + 1}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={image}
                        alt={`Project ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          transition: "transform .5s ease",
                        }}
                      />
                    </Link>
                    {isHovered === index && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "opacity 0.5s linear",
                        }}
                      >
                        <Link
                          to={`/work/project-${index + 1}`}
                          style={{ textDecoration: "none" }}
                          color="inherit"
                        >
                          <TouchButton
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Typography
                              variant="button"
                              color="white"
                              sx={{
                                p: "10px",
                                position: "relative",
                                zIndex: 1,
                              }}
                            >
                              View project
                            </Typography>
                          </TouchButton>
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
              {!imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: "3rem",
                      boxSizing: "border-box",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "start",
                      backgroundColor: "#0e0e0f",
                      width: "100%",
                      aspectRatio: "1 / 1",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    <TitleText gutterBottom>
                      {index === 1
                        ? "Enhancing veterinary care with AI."
                        : "AI-personalized supply chain for restaurants."}
                    </TitleText>
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
        {[Image1, Image2].map((image, index) => {
          const imageFirst = index % 2 === 0;

          return (
            <React.Fragment key={index}>
              {imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: "3rem",
                      boxSizing: "border-box",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "start",
                      backgroundColor: "#0e0e0f",
                      width: "100%",
                      aspectRatio: "1 / 1",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    <TitleText gutterBottom>
                      {index === 1
                        ? "Enhancing veterinary care with AI."
                        : "AI-personalized supply chain for restaurants."}
                    </TitleText>
                  </Box>
                </Grid>
              )}
              {!imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      borderRadius: "20px",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      position: "relative",
                    }}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    <Link
                      to={`/work/project-${index + 1}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={image}
                        alt={`Project ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          transition: "transform .5s ease",
                        }}
                      />
                    </Link>
                    {isHovered === index && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "opacity 0.5s linear",
                        }}
                      >
                        <Link
                          to={`/work/project-${index + 1}`}
                          style={{ textDecoration: "none" }}
                          color="inherit"
                        >
                          <TouchButton
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Typography
                              variant="button"
                              color="white"
                              sx={{
                                p: "10px",
                                position: "relative",
                                zIndex: 1,
                              }}
                            >
                              View project
                            </Typography>
                          </TouchButton>
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>

      <Box
        sx={{
          p: 3,
          borderRadius: "20px",
          textAlign: "center",
          backgroundColor: "#1C1D20",
          mt: "1rem",
        }}
      >
        <GradientText>
          83% of companies claim that AI is a top priority in their business
          plans.
        </GradientText>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {[Image3, Image4].map((image, index) => {
          const imageFirst = index % 2 === 0;

          return (
            <React.Fragment key={index}>
              {imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      borderRadius: "20px",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      position: "relative",
                    }}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    <Link
                      to={`/work/project-${index + 1}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={image}
                        alt={`Project ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          transition: "transform .5s ease",
                        }}
                      />
                    </Link>
                    {isHovered === index && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "opacity 0.5s linear",
                        }}
                      >
                        <Link
                          to={`/work/project-${index + 1}`}
                          style={{ textDecoration: "none" }}
                          color="inherit"
                        >
                          <TouchButton
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Typography
                              variant="button"
                              color="white"
                              sx={{
                                p: "10px",
                                position: "relative",
                                zIndex: 1,
                              }}
                            >
                              View project
                            </Typography>
                          </TouchButton>
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
              {!imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: "3rem",
                      boxSizing: "border-box",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "start",
                      backgroundColor: "#0e0e0f",
                      width: "100%",
                      aspectRatio: "1 / 1",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    <TitleText gutterBottom>
                      {index === 1
                        ? "Finding patterns in mitocondrial genes."
                        : "AI-powered sentiment analysis."}
                    </TitleText>
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
        {[Image3, Image4].map((image, index) => {
          const imageFirst = index % 2 === 0;

          return (
            <React.Fragment key={index}>
              {imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      p: "3rem",
                      boxSizing: "border-box",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "start",
                      backgroundColor: "#0e0e0f",
                      width: "100%",
                      aspectRatio: "1 / 1",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    <TitleText gutterBottom>
                      {index === 1
                        ? "Finding patterns in mitocondrial genes."
                        : "AI-powered sentiment analysis."}
                    </TitleText>
                  </Box>
                </Grid>
              )}
              {!imageFirst && (
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      borderRadius: "20px",
                      overflow: "hidden",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      position: "relative",
                    }}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    <Link
                      to={`/work/project-${index + 1}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={image}
                        alt={`Project ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          transition: "transform .5s ease",
                        }}
                      />
                    </Link>
                    {isHovered === index && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "opacity 0.5s linear",
                        }}
                      >
                        <Link
                          to={`/work/project-${index + 1}`}
                          style={{ textDecoration: "none" }}
                          color="inherit"
                        >
                          <TouchButton
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Typography
                              variant="button"
                              color="white"
                              sx={{
                                p: "10px",
                                position: "relative",
                                zIndex: 1,
                              }}
                            >
                              View project
                            </Typography>
                          </TouchButton>
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 4, marginBottom: 4 }}></Grid>
      <Box
        sx={{
          p: 4,
          borderRadius: "20px",
          textAlign: "center",
          backgroundColor: "#FCFCFC",
          mt: "1rem",
        }}
      >
        <TitleText gutterBottom sx={{ color: "black" }}>
          Values and work ethic
        </TitleText>
        <Typography variant="h5" color="secondary.main">
          I strive for excellence, encourage innovation, and commit to ethical
          practices and integrity in all of my endeavors.
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: 10,
          marginBottom: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          sx={{
            justifyContent: "center",
          }}
        >
          <ValuesCards>
            <SubTitleText gutterBottom className="fade show">
              Commitment
            </SubTitleText>
            <Typography
              variant="body1"
              gutterBottom
              fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              className="fade show-on-hover"
              style={{ top: "50%", transform: "translateY(-50%)" }}
            >
              I prioritize the well-being and satisfaction of every client I
              serve through meticulous data analysis and personalized data
              science solutions.
            </Typography>
          </ValuesCards>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <ValuesCards>
            <SubTitleText gutterBottom className="fade show">
              Trust
            </SubTitleText>
            <Typography
              variant="body1"
              gutterBottom
              fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              className="fade show-on-hover"
              style={{ top: "50%", transform: "translateY(-50%)" }}
            >
              Integrity is the cornerstone of my relationships with clients and
              colleagues in the field of data science.
            </Typography>
          </ValuesCards>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <ValuesCards>
            <SubTitleText gutterBottom className="fade show">
              Community
            </SubTitleText>
            <Typography
              variant="body1"
              gutterBottom
              fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              className="fade show-on-hover"
              style={{ top: "50%", transform: "translateY(-50%)" }}
            >
              I foster strong support networks with fellow data science
              enthusiasts and professionals, sharing knowledge and fostering
              collaboration.
            </Typography>
          </ValuesCards>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <ValuesCards>
            <SubTitleText gutterBottom className="fade show">
              Sustainability
            </SubTitleText>
            <Typography
              variant="body1"
              gutterBottom
              fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              className="fade show-on-hover"
              style={{ top: "50%", transform: "translateY(-50%)" }}
            >
              I advocate for sustainable practices in data management and
              algorithm efficiency to contribute to a healthier digital
              environment.
            </Typography>
          </ValuesCards>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <ValuesCards>
            <SubTitleText gutterBottom className="fade show">
              Respect
            </SubTitleText>
            <Typography
              variant="body1"
              gutterBottom
              fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              className="fade show-on-hover"
              style={{ top: "50%", transform: "translateY(-50%)" }} // Center it vertically
            >
              I uphold mutual respect with all members of the data science
              community, valuing diverse perspectives and collaborative growth.
            </Typography>
          </ValuesCards>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Work;
