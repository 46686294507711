import React from "react";
import Hero from "../components/Hero";
import Carrousel from "../components/Carrousel";
import Skills from "../components/skiils";
import Services from "../components/Services";

function Home() {
  return (
    <React.Fragment>
      <Hero />
      <Carrousel />
      <Skills />
      <Services />
    </React.Fragment>
  );
}

export default Home;
