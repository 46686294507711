import BlogPost from "../components/BlogPost";
import { Container, Grid, Typography, styled } from "@mui/material";
import BlogImage1 from "../assets/blog-post-1-min.webp";
import BlogImage2 from "../assets/blog-post-2-min.webp";
import BlogImage3 from "../assets/blog-post-3-min.webp";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "4rem",
  },
}));

function Blog() {
  // Supongamos que tienes una lista de posts; si no es así, deberías obtenerla o definirla.
  const posts = [
    {
      title: "How AI Is Changing the Game in Predictive Analytics",
      imageUrl: BlogImage1,
      content: "5 min read - feb 5, 2024",
      postID: 1,
    },
    {
      title: "Demystifying Machine Learning: What It Is & Why It Matters",
      imageUrl: BlogImage2,
      content: "5 min read - mar 16, 2024",
      postID: 2,
    },
    {
      title: "Understanding Data Analytics: A Deeper Dive into the Process",
      imageUrl: BlogImage3,
      content: "5 min read - mar 21, 2024.",
      postID: 3,
    },

    // Agrega más posts aquí según sea necesario
  ];

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        overflowX: "hidden",
        minWidth: "100%",
        paddingTop: "64px",
        background: "#1C1D20",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "5rem",
        }}
      >
        <Grid item>
          <GradientText>Blog posts</GradientText>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {posts.map((post, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {/* Ajusta para 4 posts por fila en 'md' y 1 post por fila en 'xs' */}
            <BlogPost
              title={post.title}
              imageUrl={post.imageUrl}
              content={post.content}
              postID={post.postID}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Blog;
