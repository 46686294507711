import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  styled,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import geneImage from "../assets/frontpage-gene-project.png";
import { motion } from "framer-motion";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 300,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.8rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
  },
}));

const ValuesCards = styled(motion.div)({
  background: "linear-gradient(to top, #1C1D20 0%, #000 100%)",
  color: "white",
  borderRadius: "20px",
  width: "100%",
  height: "10rem",
  display: "flex",
  flexDirection: "column", // Stack the items vertically.
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#1C1D20",
    height: "100%",
    zIndex: 0,
    transition: "bottom 0.3s ease-in-out",
    color: "white",
  },
  "&:hover::before": {
    bottom: 0,
  },
  "& .fade": {
    // Create a class for elements that should fade in/out
    position: "absolute", // Position the typographies absolutely within the card
    width: "100%",
    textAlign: "center",
    transition: "opacity 0.3s ease-in-out", // Smooth transition for the opacity
    opacity: 0, // Start with the second typography hidden
    "&.show": {
      opacity: 1, // Default visible state for the first typography
    },
  },
  "&:hover .fade": {
    opacity: 0, // On hover, hide the first typography
  },
  "&:hover .fade.show-on-hover": {
    opacity: 1, // On hover, show the second typography
  },
});

const Project3: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100%",
        pt: "5rem",
        background: "#1C1D20",
      }}
    >
      <Box
        sx={{
          my: "1rem",
          textAlign: "center",
        }}
      >
        <GradientText>Services marketplace for Biotech</GradientText>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            height: "calc(50vw - [espaciado del Grid])",
            maxWidth: "calc(50% - [espaciado del Grid])",
          }}
        >
          <Box
            sx={{
              p: "2rem",
              boxSizing: "border-box",
              borderRadius: "20px",
              backgroundColor: "#0e0e0f",
              aspectRatio: "1 / 1",
            }}
          >
            <Box
              sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                textAlign: "start",
                width: "100%",
                aspectRatio: "1 / 1",
                overflow: "scroll",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            >
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                gutterBottom
                color="#FCFCFC"
                fontWeight="900"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                About the project
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color="#FCFCFC"
                fontWeight="300"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                A centralized platform aggregating validated biotechnological
                laboratory services will enhance the process of searching,
                comparing, and selecting specialized services for biotech
                startups and SMEs by 10X.
              </Typography>
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                gutterBottom
                color="#FCFCFC"
                fontWeight="900"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Problem
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color="#FCFCFC"
                fontWeight="300"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Biotech startups and SMEs struggle to easily find and compare
                dispersed biotechnological laboratory services online,
                compounded by the need for technical knowledge to make informed
                choices.
              </Typography>
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                gutterBottom
                color="#FCFCFC"
                fontWeight="900"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Objective
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color="#FCFCFC"
                fontWeight="300"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                The biotech industry is experiencing rapid growth and is
                projected to double its market value by 2028. This growth is
                spurring a surge in startups and SMEs. In their nascent stages,
                these companies need access to top-tier laboratory services to
                propel their research and development while keeping operational
                expenses minimal turning to outsourcing as the solution.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            height: "calc(50vw - [espaciado del Grid])",
            maxWidth: "calc(50% - [espaciado del Grid])",
          }}
        >
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1 / 1",
              borderRadius: "20px",
              overflow: "hidden",
              maxWidth: "100%",
              maxHeight: "100%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Link to="/work/project-1" style={{ textDecoration: "none" }}>
              <img
                src={geneImage}
                alt="Dog image"
                style={{
                  width: "100%",
                  height: "100%", // ajusta esto según la relación de aspecto que desees
                  borderRadius: "20px",
                }}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 4, marginBottom: 4 }}>
        <Grid item>
          <Typography
            gutterBottom
            color="white"
            variant="body1"
            fontWeight="900"
            fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
          >
            2. Project Development Data Collection (with graph): "We analyzed
            thousands of past orders from a variety of restaurants to understand
            trends and preferences." Data Preprocessing (with diagram): "We
            processed this data to identify key patterns, like the most ordered
            ingredients during different seasons." Model Selection (with
            scheme): "We compared several AI techniques to find the best one for
            predicting future orders accurately." Model Training (with graph):
            "Our training process involved teaching the AI about the complex
            relationships between different order types and restaurant needs."
            Model Evaluation (with charts): "We rigorously tested the system to
            ensure it gives useful and accurate recommendations every time." 3.
            Implementation and Results UI Page (with screenshots): "We built a
            simple and intuitive interface that lets restaurant managers see
            what the AI recommends and why it's a good choice." Impact (with
            testimonials/graphics): "Restaurants using our system have seen
            smoother operations and better stock management, leading to happier
            customers and less waste." Challenges and Solutions (with bullet
            points and images): "Tackling issues like varied menu complexity, we
            fine-tuned the AI to adapt to different restaurant types."
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 4, marginBottom: 4 }}></Grid>
      <Box
        sx={{
          p: 4,
          borderRadius: "20px",
          textAlign: "center",
          backgroundColor: "#fff",
          mt: "1rem",
        }}
      >
        <Typography
          gutterBottom
          color="secondary.main"
          variant={isSmallScreen ? "h4" : "h3"}
          fontWeight="900"
          fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
        >
          Updating in progress!
        </Typography>
        <Button>
          <a
            href="https://www.behance.net/gallery/183230827/MarketplaceSaaS-for-Biotech"
            style={{ textDecoration: "underline", color: "inherit" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            See the project in Behance
          </a>
        </Button>
      </Box>
    </Container>
  );
};

export default Project3;
