import { Box, Container, Grid, Typography, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import blogPostI_2_mage_2 from "../assets/blog-post-2.webp";
import React from "react";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 300,
  textAlign: "start",
  color: "#FCFCFC",
  lineHeight: "2",
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.5rem",
  },
}));

const BlogPostContent2 = () => {
  const [copySuccess, setCopySuccess] = React.useState("");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100%",
        pt: "5rem",
        background: "#1C1D20",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "70vw" }}
      >
        <Box
          sx={{
            my: "1rem",
            textAlign: "center",
          }}
        >
          <GradientText>
            Demystifying Machine Learning: What It Is & Why It Matters
          </GradientText>
          <BodyText>5 min read - mar 20, 2024</BodyText>
        </Box>
        <Box sx={{ my: "1rem" }}>
          <img
            src={blogPostI_2_mage_2}
            alt="AI and Predictive Analytics"
            style={{ width: "100%", height: "60%", borderRadius: "20px" }}
          />
        </Box>
        <Box sx={{ my: "2rem" }}>
          <BodyText>
            Are you ready to demystify the buzzword that's been bouncing around
            the tech world? Yes, I'm talking about machine learning, the jet
            fuel that's powering everything from your Netflix recommendations to
            self-driving cars. Let's break it down into snackable pieces.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            Machine Learning Unpacked
          </SubTitleText>
          <BodyText>
            Imagine you could give your computer a magic potion that would
            enable it to learn from experience. That's machine learning in a
            nutshell. It's a branch of artificial intelligence that arms
            machines with the ability to learn and improve from data without
            being explicitly programmed for every task.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            The Ingredients of Machine Learning
          </SubTitleText>
          <BodyText>
            <li>
              Data: This is the raw material — the more quality data we feed
              into the system, the smarter it gets.
            </li>
            <li>
              Algorithms: Think of these as the recipes that the computer
              follows to learn patterns and make decisions.
            </li>
            <li>
              Models: After training with algorithms, a model is what you get.
              It's the crystal ball that makes predictions or takes actions
              based on new data.
            </li>
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>Why Should You Care?</SubTitleText>
          <BodyText>
            <li>
              Healthcare: Doctors are using machine learning to predict diseases
              earlier than ever.
            </li>
            <li>
              Business: Companies leverage it to understand their customers and
              tailor their services.
            </li>
            <li>
              Everyday Life: From spam filters in your email to voice assistants
              like
            </li>
            Siri or Alexa, machine learning is making your daily routine
            smoother and smarter.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            The Steps of Machine Learning
          </SubTitleText>
          <BodyText>
            <li>Collecting Data: Gathering the information you need.</li>
            <li>Preparing the Data: Organizing and cleaning the data.</li>
            <li>
              Choosing a Model: Picking the algorithm that suits your task.
            </li>
            <li>
              Training the Model: Feeding data into the model to learn from it.
            </li>
            <li>Evaluation: Testing the model to see how well it performs.</li>
            <li>
              Hyperparameter Tuning: Tweaking the settings to optimize the
              model.
            </li>
            <li>
              Prediction: Using the model to make informed predictions or
              decisions.
            </li>
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>The Learning Curve</SubTitleText>
          <BodyText>
            Getting started with machine learning can feel like you're climbing
            a mountain. But fear not! With plenty of resources out there, from
            online courses to coding bootcamps, you can start your journey at a
            comfortable pace and escalate your skills as you go.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>It's a Team Sport</SubTitleText>
          <BodyText>
            Machine learning isn't a solo adventure. Data scientists, engineers,
            business folks—they all come together to turn these smart algorithms
            into real-world magic.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>In a Nutshell</SubTitleText>
          <BodyText>
            Machine learning is a game-changer, and it's reshaping our future
            one prediction at a time. Dive in, get your hands dirty with data,
            and you'll be part of the revolution that's changing the face of
            technology.
          </BodyText>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          color: "white",
        }}
      >
        <Tooltip title={copySuccess || "Copy link"} placement="top" arrow>
          <IconButton
            onClick={copyToClipboard}
            sx={{ color: "white", transform: "scale(1.5)" }}
          >
            <IosShareRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #555",
          my: 2,
        }}
      />
    </Container>
  );
};

export default BlogPostContent2;
