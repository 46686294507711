import React from "react";
import { Box, Grid, Typography, Link, styled, IconButton } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CookieRoundedIcon from "@mui/icons-material/CookieRounded";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #38383d 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "3.5rem",
  },
}));

const Footer: React.FC = () => {
  const controls = useAnimation();

  const TouchButton = styled(motion.div)({
    backgroundColor: "#6A00DD",
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative", // Importante para posicionar el pseudo-elemento
    overflow: "hidden", // Asegúrate de que el relleno no se salga del botón
    "&::before": {
      // Pseudo-elemento para el efecto de llenado
      content: '""',
      position: "absolute",
      bottom: "100%", // Empieza fuera de la vista, abajo del botón
      left: 0,
      right: 0,
      backgroundColor: "#000",
      height: "100%",
      zIndex: 0,
      transition: "bottom 0.3s ease-in-out", // Anima la propiedad bottom
    },
    "&:hover::before": {
      bottom: 0, // Al hacer hover, mueve el pseudo-elemento para "llenar" el botón
    },
  });

  return (
    <Box
      sx={{
        bgcolor: "#1C1D20",
        color: "#FCFCFC",
        pt: 5,
        pb: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "400px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <GradientText textAlign="center" sx={{ paddingRight: "10px" }}>
          Let's work together
        </GradientText>

        <TouchButton whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
          <Link
            href="mailto:your.mmoncayod@gmail.com"
            color="inherit"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="button"
              color="inherit"
              sx={{ p: "10px", position: "relative", zIndex: 1 }}
            >
              Get in touch
            </Typography>
          </Link>
        </TouchButton>
      </Box>

      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #555",
          my: 2,
        }}
      />

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={6} sm={3} textAlign="center">
          <Typography variant="caption">2024 © Edition</Typography>
        </Grid>
        <Grid item xs={6} sm={3} textAlign="center">
          <Typography variant="caption">mmoncayod@gmail.com</Typography>
        </Grid>
        <Grid item xs={12} sm={3} textAlign="center">
          <Link
            href="https://www.linkedin.com/in/miguelamd/"
            color="inherit"
            style={{ margin: "0 10px" }}
          >
            <IconButton
              color="inherit"
              sx={{
                color: "inherit",
                margin: "0 10px",
                "&:hover": {
                  transform: "scale(1.2)",
                  boxShadow: "0px 0px 10px 0px #9C87B5",
                  transition:
                    "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                },
              }}
            >
              <LinkedInIcon />
            </IconButton>
          </Link>
          <Link
            href="https://github.com/mmoncayod"
            color="inherit"
            style={{ margin: "0 10px" }}
          >
            <IconButton
              color="inherit"
              sx={{
                color: "inherit",
                margin: "0 10px",
                "&:hover": {
                  transform: "scale(1.2)",
                  boxShadow: "0px 0px 10px 0px #9C87B5",
                  transition:
                    "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                },
              }}
            >
              <GitHubIcon />
            </IconButton>
          </Link>
          <Link href="https://www.instagram.com/mhos_s/?hl=es" color="inherit">
            <IconButton
              color="inherit"
              sx={{
                color: "inherit",
                margin: "0 10px",
                "&:hover": {
                  transform: "scale(1.2)",
                  boxShadow: "0px 0px 10px 0px #9C87B5",
                  transition:
                    "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                },
              }}
            >
              <InstagramIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item xs={12} sm={3} textAlign="center">
          <Link href="https://www.miguelangelmd.com/cookies" color="inherit">
            <IconButton
              color="inherit"
              sx={{
                color: "inherit",
                margin: "0 10px",
                "&:hover": {
                  transform: "scale(1.2)",
                  boxShadow: "0px 0px 10px 0px #9C87B5",
                  transition:
                    "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                },
              }}
            >
              <CookieRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
