import React from "react";
import {
  Box,
  Chip,
  Grid,
  Typography,
  styled,
  Tooltip,
  TooltipProps,
} from "@mui/material";

import { motion } from "framer-motion";

const SkillChip = styled(Chip)(({ theme }) => ({
  borderColor: "#1C1D20",
  borderWidth: "2px",
  color: "#1C1D20",
  margin: theme.spacing(1),
  "&:hover": {
    background: "#9C87B5",
    color: "#1C1D20",
    cursor: "pointer",
    borderWidth: "0",
    boxShadow: "0px 0px 25px 10px #FCFCFC",
    transform: "scale(1.1)",
    transition: "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
  },
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#FCFCFC",
    color: "#1C1D20",
    fontSize: "0.7em",
    border: `0.5px solid ${theme.palette.divider}`,
    borderRadius: 10,
  },
}));

const skillsData: { label: string; tooltip: string }[] = [
  {
    label: "Product management",
    tooltip:
      "Jira, Trello, Slack, Mixpanel, Google Analytics, LogRocket, Hotjar",
  },
  {
    label: "Programming",
    tooltip: "JavaScript, Python, SQL, GraphQL, React js, Git",
  },
  { label: "UX/UI design", tooltip: "Figma, Adobe XD" },
  {
    label: "Data sciecne/ML",
    tooltip:
      "Google coolab, Scikit-learn, Seaborn, Matplotlib, Tableu, Pandas, NumPy",
  },
];

const SkillsSection: React.FC = () => {
  return (
    <Grid
      container
      sx={{
        padding: "1rem",
        mt: "2rem",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography
          variant="h3"
          fontWeight="900"
          fontFamily="'Helvetica Neue', Helvetica, Arial, sans-serif"
        >
          My skills
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ bgcolor: "white", color: "white", p: 4 }}>
          <Grid container justifyContent="center" alignItems="center">
            {skillsData.map((skill) => (
              <CustomTooltip title={skill.tooltip} key={skill.label} arrow>
                <SkillChip label={skill.label} variant="outlined" />
              </CustomTooltip>
            ))}
          </Grid>
        </Box>
      </Grid>
      {/* La curva de revelación */}
      <Box sx={{ position: "relative", height: "200px", overflow: "hidden" }}>
        <motion.div
          initial={{ scaleY: 0 }}
          whileInView={{ scaleY: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "200px",
            background: "white",
            transformOrigin: "bottom center",
            borderRadius: "50% / 10%",
          }}
        />
      </Box>
    </Grid>
  );
};

export default SkillsSection;
