import { Box, Container, Grid, Typography, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import blogPostI_1_mage_1 from "../assets/blog-post-1.webp";
import React from "react";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  color: "#FCFCFC",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 300,
  textAlign: "start",
  color: "#FCFCFC",
  lineHeight: "2",
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.5rem",
  },
}));

const BlogPostContent1 = () => {
  const [copySuccess, setCopySuccess] = React.useState("");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100%",
        pt: "5rem",
        background: "#1C1D20",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "70vw" }}
      >
        <Box
          sx={{
            my: "1rem",
            textAlign: "center",
          }}
        >
          <GradientText>
            How AI Is Changing the Game in Predictive Analytics
          </GradientText>
          <TitleText>An overview</TitleText>
          <BodyText>5 min read - mar 20, 2024</BodyText>
        </Box>
        <Box sx={{ my: "1rem" }}>
          <img
            src={blogPostI_1_mage_1}
            alt="AI and Predictive Analytics"
            style={{ width: "100%", height: "60%", borderRadius: "20px" }}
          />
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            What happens when you mix AI with predictive analytics?
          </SubTitleText>
          <BodyText>
            Well, you get a super tool. Predictive analytics uses data to guess
            the future, and AI kicks it up a notch. Imagine having a super brain
            that not only learns from heaps of past data but also makes super
            accurate predictions about what could happen next.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            The secret ingredients
          </SubTitleText>
          <BodyText>
            <li>
              Machine Learning: It's like teaching your computer new tricks so
              it gets smarter over time.
            </li>
            <li>
              Deep Learning: Think of this as the genius sibling of machine
              learning that can understand super complex patterns.
            </li>
            <li>
              Natural Language Processing (NLP): This helps the computer
              understand and speak our language.
            </li>
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>
            Where's it changing the game?
          </SubTitleText>
          <BodyText>
            From healthcare to finance, and retail to manufacturing, AI's
            predictive powers are transforming industries. Imagine predicting
            diseases before symptoms appear or forecasting market trends with
            uncanny accuracy.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>It's not all rosy</SubTitleText>
          <BodyText>
            With all these cool things come some challenges. Stuff like keeping
            our data private, making sure AI is fair, and understanding how it
            comes to its conclusions are super important.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>Looking ahead</SubTitleText>
          <BodyText>
            What's coming is even more exciting. We're just scratching the
            surface of what AI and predictive analytics can do together. One
            thing's for sure, their impact on our lives is only going to grow.
          </BodyText>
        </Box>
        <Box sx={{ my: "2rem" }}>
          <SubTitleText sx={{ mb: "1rem" }}>To wrap it up</SubTitleText>
          <BodyText>
            The blend of AI with predictive analytics is doing amazing things,
            and we're just getting started. As we continue to refine these
            technologies, the possibilities are endless. Get ready, because the
            future looks bright (and super smart!).
          </BodyText>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          color: "white",
        }}
      >
        <Tooltip title={copySuccess || "Copy link"} placement="top" arrow>
          <IconButton
            onClick={copyToClipboard}
            sx={{ color: "white", transform: "scale(1.5)" }}
          >
            <IosShareRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #555",
          my: 2,
        }}
      />
    </Container>
  );
};

export default BlogPostContent1;
