import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  styled,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import RaiilImage from "../assets/frontpage-raiil-project.png";
import { motion } from "framer-motion";

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #6A00DD 0%, #FCFCFC 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 300,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.8rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
  },
}));

const ValuesCards = styled(motion.div)({
  background: "linear-gradient(to top, #1C1D20 0%, #000 100%)",
  color: "white",
  borderRadius: "20px",
  width: "100%",
  height: "10rem",
  display: "flex",
  flexDirection: "column", // Stack the items vertically.
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#1C1D20",
    height: "100%",
    zIndex: 0,
    transition: "bottom 0.3s ease-in-out",
    color: "white",
  },
  "&:hover::before": {
    bottom: 0,
  },
  "& .fade": {
    // Create a class for elements that should fade in/out
    position: "absolute", // Position the typographies absolutely within the card
    width: "100%",
    textAlign: "center",
    transition: "opacity 0.3s ease-in-out", // Smooth transition for the opacity
    opacity: 0, // Start with the second typography hidden
    "&.show": {
      opacity: 1, // Default visible state for the first typography
    },
  },
  "&:hover .fade": {
    opacity: 0, // On hover, hide the first typography
  },
  "&:hover .fade.show-on-hover": {
    opacity: 1, // On hover, show the second typography
  },
});

const Project1: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100%",
        pt: "5rem",
        background: "#1C1D20",
      }}
    >
      <Box
        sx={{
          my: "1rem",
          textAlign: "center",
        }}
      >
        <GradientText>Ordering app for restaurants</GradientText>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            height: "calc(50vw - [espaciado del Grid])",
            maxWidth: "calc(50% - [espaciado del Grid])",
          }}
        >
          <Box
            sx={{
              p: "2rem",
              boxSizing: "border-box",
              borderRadius: "20px",
              backgroundColor: "#0e0e0f",
              aspectRatio: "1 / 1",
            }}
          >
            <Box
              sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                textAlign: "start",
                width: "100%",
                aspectRatio: "1 / 1",
                overflow: "scroll",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            >
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                gutterBottom
                color="#FCFCFC"
                fontWeight="900"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                About the project
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color="#FCFCFC"
                fontWeight="300"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                The objective was to design and create a cloud-based management
                platform that simplifies and streamlines the order management
                process for restaurants. By providing an efficient, transparent
                system for handling orders, tracking, and payments, Raiil
                enhances the relationship between restaurants and suppliers,
                ensuring a more seamless supply chain operation.
              </Typography>
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                gutterBottom
                color="#FCFCFC"
                fontWeight="900"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Problem:
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color="#FCFCFC"
                fontWeight="300"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Restaurant managers spend 8 hours/week manually setting up
                orders to suppliers.
              </Typography>
              <Typography
                variant={isSmallScreen ? "h4" : "h3"}
                gutterBottom
                color="#FCFCFC"
                fontWeight="900"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Objective:
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color="#FCFCFC"
                fontWeight="300"
                fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
              >
                Lead the product roadmap for our B2B E-commerce for restaurants.
                Working closely with clients, UX, marketing, engineering, and
                sales teams, we achieved a significant milestone, expanding our
                reach to over 300 businesses across Spain and LATAM within a
                single year, marking a 150% growth in our client base.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{}}>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1 / 1",
              borderRadius: "20px",
              overflow: "hidden",
              maxWidth: "100%",
              maxHeight: "100%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            /*
            <Link to="/work/project-1" style={{ textDecoration: "none" }}>
              <img
                src={RaiilImage}
                alt="Dog image"
                style={{
                  width: "100%",
                  height: "100%", // ajusta esto según la relación de aspecto que desees
                  borderRadius: "20px",
                }}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 4, marginBottom: 4 }}>
        <Grid item>
          <Box></Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 4, marginBottom: 4 }}></Grid>
      <Box
        sx={{
          p: 4,
          borderRadius: "20px",
          textAlign: "center",
          backgroundColor: "#fff",
          mt: "1rem",
        }}
      >
        <Typography
          gutterBottom
          color="secondary.main"
          variant={isSmallScreen ? "h4" : "h3"}
          fontWeight="900"
          fontFamily='"Helvetica Neue", Helvetica, Arial, sans-serif'
        >
          Updating in progress!
        </Typography>
        <Button>
          <a
            href="https://www.behance.net/gallery/186153377/Ordering-app-for-restaurants"
            style={{ textDecoration: "underline", color: "inherit" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            See the project in Behance
          </a>
        </Button>
      </Box>
    </Container>
  );
};

export default Project1;
