// Hero.tsx
import React from "react";
import { styled } from "@mui/system";
import { Typography, Button, IconButton, Grid, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import ProfileImageSrc from "../assets/miguelangelmd-profile.jpg";

const HeroSection = styled(Grid)(({ theme }) => ({
  minHeight: "800px",
  background: "radial-gradient(at right center, #000000, #38186d)",
  color: "white",
  padding: theme.spacing(8),
  [theme.breakpoints.down("md")]: {
    minHeight: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4),
  },
}));

const StyledProfileImage = styled("img")({
  width: "100%",
  maxWidth: "400px",
  height: "auto",
  borderRadius: "30px",
  transform: "rotate(10deg)",
  "&:hover": {
    transform: "scale(1.1) rotate(0deg)",
    borderRadius: "10%",
    boxShadow: "0px 0px 50px 5px #312247",
    transition: "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
  },
});

const HeroText = styled(Typography)(({ theme }) => ({
  color: "white",
  fontFamily: '"Roboto", Arial, sans-serif',
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(to right, #fff 0%, #330867 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 900,
  [theme.breakpoints.up("lg")]: {
    fontSize: "6rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "4.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "4rem",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  color: "white",
  fontFamily: '"Roboto", Arial, sans-serif',
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const Hero: React.FC = () => {
  return (
    <HeroSection container alignItems="center">
      <Grid item xs={12} md={7}>
        <HeroText variant="h5" gutterBottom>
          Hi!
        </HeroText>
        <GradientText variant="h1" gutterBottom style={{ fontWeight: 900 }}>
          I am Miguel Ángel
        </GradientText>
        <BodyText paragraph style={{ color: "white", lineHeight: "1.5" }}>
          I am a product development enthusiast with over eight years of
          experience in deep tech, SaaS, and e-commerce. My focus lies in
          leveraging data analytics and artificial intelligence (AI) to improve
          conversion rate optimization (CRO), user experience (UX), and the
          development of intuitive user interfaces (UI) that encourage
          engagement. I make sure to keep an eye on key performance indicators
          (KPIs) to continually monitor product performance over time.
        </BodyText>
        <Grid container spacing={2} marginTop={2}>
          <Grid item>
            <Link
              href="https://www.linkedin.com/in/miguelamd"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                  transform: "scale(1.5)",
                },
              }}
            >
              <IconButton
                sx={{
                  color: "inherit",
                  "&:hover": {
                    transform: "scale(1.5)",
                    boxShadow: "0px 0px 10px 0px #9C87B5",
                    transition:
                      "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                  },
                }}
              >
                <LinkedInIcon />
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="https://github.com/mmoncayod/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <IconButton
                color="inherit"
                sx={{
                  color: "inherit",
                  "&:hover": {
                    transform: "scale(1.5)",
                    boxShadow: "0px 0px 10px 0px #9C87B5",
                    transition:
                      "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                  },
                }}
              >
                <GitHubIcon />
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="mailto:your.mmoncayod@gmail.com"
              color="inherit"
              style={{ textDecoration: "none" }}
            >
              <IconButton
                color="inherit"
                sx={{
                  color: "inherit",
                  "&:hover": {
                    transform: "scale(1.5)",
                    boxShadow: "0px 0px 10px 0px #9C87B5",
                    transition:
                      "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                  },
                }}
              >
                <EmailIcon />
              </IconButton>
            </Link>
          </Grid>
          <Grid item>
            <Button
              href="https://drive.google.com/file/d/16T2rpA4dfcsj44QRvr_qaJip1A9EgT-v/view?usp=sharing"
              target="_blank"
              variant="outlined"
              color="secondary"
              sx={{
                borderRadius: "50px",
                borderColor: "white",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  borderColor: "white",
                  transform: "scale(1.2)",
                  boxShadow: "0px 0px 10px 0px #9C87B5",
                  transition:
                    "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
                },
              }}
            >
              Download CV
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          order: { xs: -1, md: 0 },
          mb: "1.5rem",
        }}
      >
        <StyledProfileImage src={ProfileImageSrc} alt="Profile" />
      </Grid>
    </HeroSection>
  );
};

export default Hero;
