import * as React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

interface BlogPostProps {
  title: string;
  imageUrl: string;
  content: string;
  postID: number;
}

const BlogPost: React.FC<BlogPostProps> = ({
  title,
  imageUrl,
  content,
  postID,
}) => {
  return (
    <Card sx={{ maxWidth: 400, marginBottom: 2 }}>
      <Link
        to={`/post/${postID}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="200"
            image={imageUrl}
            alt={`Image for ${title}`}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {content}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default BlogPost;
