import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Blog from "./pages/Blog";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Project1 from "./pages/Project1";
import Project2 from "./pages/Project2";
import Project3 from "./pages/Project3";
import Project4 from "./pages/Project4";
import BlogPostContent1 from "./components/BlogPostContent1";
import BlogPostContent2 from "./components/BlogPostContent2";
import BlogPostContent3 from "./components/BlogPostContent3";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/work" element={<Work />} />
          <Route path="/work/project-1" element={<Project1 />} />
          <Route path="/work/project-2" element={<Project2 />} />
          <Route path="/work/project-3" element={<Project3 />} />
          <Route path="/work/project-4" element={<Project4 />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/post/1" element={<BlogPostContent1 />} />
          <Route path="/post/2" element={<BlogPostContent2 />} />
          <Route path="/post/3" element={<BlogPostContent3 />} />
          <Route path="/" element={<Home />} />
          {/* Más rutas según sea necesario */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
