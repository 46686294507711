import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import "./HamburgerMenu.css";

const HamburgerMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const controls = useAnimation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const boundingBox = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - boundingBox.left;
    const mouseY = event.clientY - boundingBox.top;
    const buttonX = boundingBox.width / 2;
    const buttonY = boundingBox.height / 2;
    const deltaX = mouseX - buttonX;
    const deltaY = mouseY - buttonY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const maxDistance = 50;

    if (distance < maxDistance) {
      controls.start({
        x: deltaX,
        y: deltaY,
        transition: { duration: 0 },
      });
    } else {
      controls.start({
        x: 0,
        y: 0,
        transition: { type: "spring", stiffness: 300, damping: 20 },
      });
    }
  };

  const handleMouseLeave = () => {
    controls.start({
      x: 0,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 20 },
    });
  };

  const buttonVariants = {
    opened: { rotate: 360, backgroundColor: "#521BA8" },
    closed: { rotate: 0, backgroundColor: "#e0e0e0" },
  };

  const menuVariants = {
    opened: {
      scale: 5,
      x: 0,
      originX: 0.8,
      originY: 0,
      transition: { duration: 0.3 },
    },
    closed: {
      scale: 0,
      x: "100%",
      transition: { duration: 0.3 },
    },
  };

  const linkContainerVariants = {
    opened: { scale: 0.4, transition: { duration: 0.5 } },
    closed: { scale: 1 },
  };

  return (
    <div
      className="hamburger-menu"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <motion.button
        onClick={toggleMenu}
        animate={controls}
        whileHover={{ scale: 1.2 }}
        variants={buttonVariants}
        className={`menu-button ${isMenuOpen ? "opened" : ""}`}
      >
        {isMenuOpen ? "✖" : "☰"}
      </motion.button>
      <motion.div
        className="menu-content"
        initial="closed"
        animate={isMenuOpen ? "opened" : "closed"}
        variants={menuVariants}
      >
        <motion.div
          className="links-container"
          variants={linkContainerVariants}
        >
          <h3>Menu</h3>
          <Link to="/">Home</Link> {/* Cambiado de <a> a <Link> */}
          <Link to="/work">Work</Link> {/* Cambiado de <a> a <Link> */}
          <Link to="/blog">Blog</Link> {/* Cambiado de <a> a <Link> */}
        </motion.div>
      </motion.div>
      {isMenuOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 5,
          }}
          onClick={toggleMenu}
        />
      )}
    </div>
  );
};

export default HamburgerMenu;
